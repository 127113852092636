import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/x",
    name: "landing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/Landing.vue"),
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/Login.vue"),
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/Dashboard.vue"),
  },
  {
    path: "/staff",
    name: "Staff",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/Staff.vue"),
  },
  {
    path: "/farmers",
    name: "Farmers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/Farmers.vue"),
  },

  {
    path: "/inputs",
    name: "Input",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/Inputs.vue"),
  },

  {
    path: "/produce",
    name: "Produce",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/Produce.vue"),
  },

  {
    path: "/organizations",
    name: "Organizations",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/pages/Organizations.vue"
      ),
  },

  {
    path: "/farmer/:id",
    name: "SingleFarmer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/SingleFarmer.vue"),
   redirect:"/farmer/:id/farms",
    children: [
      {
        path: "farms",
        name: "SingleFarmerFarms",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/pages/SingleFarmerFarms.vue"
          ),
      },

      {
        path: "finance",
        name: "SingleFarmerFinance",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/pages/SingleFarmerFinance.vue"
          ),
      },
    ],
  },
  {
    path: "/add-staff",
    name: "AddStaff",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/AddStaff.vue"),
  },
  {
    path: "/add-farmer",
    name: "AddFarmer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/AddFarmer.vue"),
  },

  {
    path: "/add-farm/:id",
    name: "AddFarm",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/AddFarm.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
