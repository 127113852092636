import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueToastr from "vue-toastr";
import Toasted from "vue-toasted";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

Vue.config.productionTip = false


Vue.use(VueAxios, axios)
Vue.use(Toasted);
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});
defineCustomElements(window);


// const baseURL = process.env.NODE_ENV === 'production'
//   ? process.env.VUE_APP_BASE_URL
//   : process.env.VUE_APP_BASE_URL;


window.axios=axios
axios.defaults.baseURL = 'https://api-dev.one-farm.ng';
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
