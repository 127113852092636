<template>
  <div>
    <router-view />
  </div>
</template>

<style>
nav a.router-link-exact-active {
  @apply border flex items-center text-white p-2 rounded-lg py-3 bg-primary;
}
</style>

<script>

export default {
  
  data: () => ({
    //
  }),

 
  computed: {},
};
</script>

